import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './DemoPages/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import api from './api';

require('dotenv').config()

const store = configureStore();
const history = createBrowserHistory();
const rootElement = document.getElementById('root');

api.interceptors.response.use(response => {
    return response;
}, error => {
    const { config, response } = error;

    if (response && response.status === 403) {
        console.log('Forbidden')
        window.location = "#/login"
    }
    return Promise.reject(error);
})

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter history={history}>
                <Component />
            </HashRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./DemoPages/Main', () => {
        const NextApp = require('./DemoPages/Main').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();