import React from 'react';

const LoginContext = React.createContext({
    user: { menuItems: [] },
    setUser: () => { }
});

// export class LoginProvider extends React.Component {
//     state = {
//         user: null
//     };

//     login = user => {
//         this.setState({ user: user })
//     };

//     logout = () => this.setState({ user: null });

//     render() {
//         return (
//             <LoginContext.Provider
//                 value={{
//                     user: this.state.user,
//                     login: this.login,
//                     logout: this.logout
//                 }}>
//                 {this.props.children}
//             </LoginContext.Provider>
//         )
//     }
// }

export default LoginContext;