import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';


const Applications = lazy(() => import('DemoPages/Applications'));
const Dashboards = lazy(() => import('../../DemoPages/Dashboards'));

const Offers = lazy(() => import('../../DemoPages/Offers'))
const Partners = lazy(() => import('../../DemoPages/Partners'))
const Sales = lazy(() => import('../../DemoPages/Sales'));
const SalesMen = lazy(() => import('../../DemoPages/SalesMen'))
const LoginComponent = lazy(() => import('../../DemoPages/Login'))
const RouteComponent = lazy(() => import('../../DemoPages/Route'))
const RouteCallCenter = lazy(() => import('../../DemoPages/CallCenter'))
const FileExplorer = lazy(() => import('../../DemoPages/FileExplorer'))
const Organization = lazy(() => import('DemoPages/Organization'))
const Prices = lazy(() => import('DemoPages/Prices'))

const FallBack = <div className="loader-container">
    <div className="loader-container-inner">
        <div className="text-center">
            <Loader type="ball-pulse-rise" />
        </div>
        <h6 className="mt-5">
            Va rugam asteptati
        <small></small>
        </h6>
    </div>
</div>

const AppMain = () => {

    return (
        <Fragment>

            {/* <Switch> */}
            {/** Login page */}
            <Suspense fallback={FallBack}>
                <Route path="/login" component={LoginComponent} />
            </Suspense>

            <Suspense fallback={FallBack}>
                <Route path="/callcenter" component={RouteCallCenter} />
            </Suspense>

            {/** Route */}
            <Suspense fallback={FallBack}>
                <Route path="/route" component={RouteComponent} />
            </Suspense>

            {/** Offers */}
            <Suspense fallback={FallBack}>
                <Route path="/offers" component={Offers} />
            </Suspense>

            {/** Partners */}
            <Suspense fallback={FallBack}>
                <Route path="/partners" component={Partners} />
            </Suspense>

            {/** Sales */}
            <Suspense fallback={FallBack}>
                <Route path="/sales" component={Sales} />
            </Suspense>

            {/** Salesmen */}
            <Suspense fallback={FallBack}>
                <Route path="/salesmen" component={SalesMen} />
            </Suspense>

            {/* Applications */}

            <Suspense fallback={FallBack}>
                <Route path="/apps" component={Applications} />
            </Suspense>

            {/* Dashboards */}

            <Suspense fallback={FallBack}>
                <Route path="/dashboards" component={Dashboards} />
            </Suspense>

            {/* File explorer */}
            <Suspense fallback={FallBack}>
                <Route path="/files" component={FileExplorer} />
            </Suspense>

            {/* Organization */}
            <Suspense fallback={FallBack}>
                <Route path="/organization" component={Organization} />
            </Suspense>

            {/* Prices */}
            <Suspense fallback={FallBack}>
                <Route path="/prices" component={Prices} />
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/login" />
            )} />

            {/* <Route render={() => {
                return <Redirect to="/login"></Redirect>
            }}></Route> */}
            {/* </Switch> */}

            <ToastContainer />
        </Fragment >
    )
};

export default AppMain;