import axios from 'axios';

const instance = axios.create({
    baseURL: "https://api.e-node.ro/crmcaretta/",
    // baseURL: "https://api.e-node.ro/v1/david/"
    // baseURL: "http://localhost:18099/"
});

instance.interceptors.request.use(request => {
    request.headers['x-access-token'] = localStorage.getItem('TOKEN')
    return request;
})

export default instance;